import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditFeedbackFormsComponent } from './add-edit-feedback-forms.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [AddEditFeedbackFormsComponent],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    TooltipModule.forRoot(),
    NgxSliderModule
  ],
  exports: [AddEditFeedbackFormsComponent],
  entryComponents: [AddEditFeedbackFormsComponent]
})
export class AddEditFeedbackFormsModule { }
