
import { downgradeComponent } from "@angular/upgrade/static";
import { JobProfilePageComponent } from './job-profile-page.component';

export const JobProfilePageAjsModule = angular
    .module('jobProfilePage', [])
    .directive('jobProfilePage', downgradeComponent({ component: JobProfilePageComponent }))
    .config(
        ['$stateProvider', '$transitionsProvider', ($stateProvider, $transitionsProvider) => {
            $stateProvider.state('missioncontrol.jobprofile', {
                url: '/jobprofile',
                component: JobProfilePageComponent,
                data: {
                    requireLogin: true
                },
                params: {
                  jobId: null
                }
            });
        }

        ])
    .name;