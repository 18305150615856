export class NewJDAction {
    id: any = null;
    order: Number = null;
    actionword: String = '';
    description: String = '';
    minExperienceInMonths: Object = {
        value: null,
        unit: "months",
        sign: ""
    };
    maxExperienceInMonths: Object = {
        value: null,
        unit: "months",
        sign: ""
    };
    jobDescriptionLanguageDropdown: any = [];
    alternates: any = [];
}