import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { JobProfile } from 'src/app/core/models/jobProfile';
import { RunCandidateScore } from 'src/app/core/models/runCandidateScore';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { JobService } from 'src/app/core/services/job.service';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { ResumeService } from 'src/app/core/services/resume.service';
import { UserRoleService } from 'src/app/core/services/user-role.service';

@Component({
  selector: 'app-job-profile',
  templateUrl: './job-profile.component.html',
  styleUrls: ['./job-profile.component.css']
})
export class JobProfileComponent implements OnInit {


  @Input() jobId;
  @Input() context;

  job: any;
  companyId: any;
  userId: any;
  requisitionUrl: SafeUrl;
  jobReportPdfUrl: SafeUrl;
  jobDownloadPdfUrl: SafeUrl;
  jobReportPdfViewUrl: SafeUrl;
  jobCopy: any;

  loadingFlag: boolean = true;
  isEdit: boolean = false;
  isSkillsEditSaved: boolean = false;
  showEdit: boolean = false;
  isSuperUserOr4dot5Admin: boolean;

  constructor(
    @Inject('$rootScope') public $rootScope:any,
    @Inject('requisitionService') public requisitionService:any,
    private userRoleService: UserRoleService,
    private jobService: JobService,
    private companyService: CompanyService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private modalService: AngularModalService,
    private resumeService: ResumeService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    //variables
    this.requisitionUrl = '';
    this.companyId = this.$rootScope.userDetails.company.companyId;
    this.userId = this.$rootScope.userDetails.id;

    this.isSuperUserOr4dot5Admin = this.userRoleService.isLoggedInUserSuperUserOr4dot5Admin();
    this.job = new JobProfile();
    this.requisitionUrl = this.sanitizeUrl(this.requisitionService.getRequisitionDocumentUrl(this.jobId));
    this.jobReportPdfUrl = this.sanitizeUrl(this.jobService.getJobReportPdfUrl(this.jobId));
    this.jobDownloadPdfUrl = this.sanitizeUrl(this.jobService.getJobDownloadPdfUrl(this.jobId));
    this.jobReportPdfViewUrl = this.sanitizeUrl(this.jobReportPdfUrl + '#view=fitH');
    this._getJobProfile();
  }

  sanitizeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}

  _getJobProfile() {
    this.jobService.getJobProfile(this.jobId, (data) => {
      _.merge(this.job, data);
      this._setDeleteFlags();
      this._checkJobPdfFileExists(this.job);
      this.jobCopy = angular.copy(this.job);
      this.loadingFlag = false;
      this.showEdit = this.isSuperUserOr4dot5Admin && !(this.companyService.isStaffingCompany() && this.job.sharedRequisition);
    }, (error) => {
      // do nothing.
    });
  }

  _setDeleteFlags() {
    if (this.job.educations) {
      this.job.educations.forEach(education => {
        education.isDeleted = false;
      });
    } else {
      this.job.educations = [];
    }

    if (this.job.experiences) {
      this.job.experiences.forEach(experience => {
        experience.isDeleted = false;
      });
    } else {
      this.job.experiences = [];
    }

    if (this.job.technicalSkills) {
      this.job.technicalSkills.forEach(skill => {
        skill.isDeleted = false;
      });
    } else {
      this.job.technicalSkills = [];
    }

    if (this.job.operationalSkills) {
      this.job.operationalSkills.forEach(skill => {
        skill.isDeleted = false;
      });
    } else {
      this.job.operationalSkills = [];
    }

    if (this.job.softSkills) {
      this.job.softSkills.forEach(skill => {
        skill.isDeleted = false;
      });
    } else {
      this.job.softSkills = [];
    }

    if (this.job.extraSkills) {
      this.job.extraSkills.forEach(skill => {
        skill.isDeleted = false;
      });
    } else {
      this.job.extraSkills = [];
    }

    if (this.job.certifications) {
      this.job.certifications.forEach(certification => {
        certification.isDeleted = false;
      });
    } else {
      this.job.certifications = [];
    }

    if (this.job.compensations) {
      this.job.compensations.forEach(compensation => {
        compensation.isDeleted = false;
      });
    } else {
      this.job.compensations = [];
    }
  }

  _checkJobPdfFileExists(job) {
    job.isJobFilePdf = false;
    if (job.jobRequisition && job.jobRequisition.fileName) {
      job.isJobFilePdf = (job.jobRequisition.fileName.slice(-4)) === '.pdf' ? true : false;
    }
  }

  undoEdit() {
    if (this._hasDataChanged()) {
      let title = "Alert!";
      let message = "All the deleted elements will be NOT be removed from the Requisition. The Requisition will be put back to its previous state.<br> Are you sure?";
      this.alertsAndNotificationsService.showConfirm(title, message, "warning", false, () => {
        this.job = angular.copy(this.jobCopy);
        this.isEdit = false;
        this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      }, () => {
        //do nothing
        this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      });
    } else {
      this.isEdit = false;
    }
  }

  save() {
    if (this._hasDataChanged()) {
      let title = "Alert!";
      let message = "All the deleted elements will be removed permanently from the Requisition.<br> Are you sure?";
      this.alertsAndNotificationsService.showConfirm(title, message, "warning", false, () => {
        this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
        let educations = this.job.educations.filter(education => education.isDeleted).map(edu => ({ degreeName: edu.degreeName, degreeType: edu.degreeType }));
        let technicalSkills = this.job.technicalSkills.filter(skill => skill.isDeleted).map(skill => ({ name: skill.competency }));
        let softSkills = this.job.softSkills.filter(skill => skill.isDeleted).map(skill => ({ name: skill.competency }));
        let operationalSkills = this.job.operationalSkills.filter(skill => skill.isDeleted).map(skill => ({ name: skill.competency }));
        let otherSkills = this.job.extraSkills.filter(skill => skill.isDeleted).map(skill => ({ name: skill.competency
          
         }));

        let requisitionElementsToDelete = {
          jobId: this.jobId,
          companyId: this.companyId,
          educations: educations,
          technicalSkills: technicalSkills,
          softSkills: softSkills,
          operationalSkills: operationalSkills,
          otherSkills: otherSkills
        };
        this.jobService.deleteRequisitionElements(requisitionElementsToDelete, data => {
          this.job = data;
          this._setDeleteFlags();
          this.jobCopy = angular.copy(this.job);
          this.isSkillsEditSaved = true;
          this.isEdit = false;
          this._showRecalculateScoreConfirmation();
        }, error => {
          this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
        });
      }, () => {
        //do nothing
        this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      });
    } else {
      this.alertsAndNotificationsService.showAlert("No changes made", "No changes made to save", "warning");
      this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    }
  }

  _hasDataChanged() {
    let dataChanged = !angular.equals(this.job, this.jobCopy);
    return dataChanged;
  }

  _showRecalculateScoreConfirmation() {
    let title = "Recalculate Score";
    let message = "Elements have been deleted. Do you want to recalculate the score?";
    this.alertsAndNotificationsService.showConfirm(title, message, "info", false, () => {
      this.recalculateScore();
      this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    }, () => {
      //do nothing
      this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
    });
  }

  recalculateScore() {
    // this.utilityService.showLoadingModal("Recalculating Score...");
    // api call to recalculate the score;
    let requestObject = new RunCandidateScore(this.companyId, this.userId);
    requestObject.jobId = this.jobId;
    requestObject.rerunType = "REQUISITION";
    this.resumeService.reRunResumeScore(requestObject, (data) => {
      // this.jobService.getJobMatchScores(this.jobId, (data) => {
      this.isSkillsEditSaved = false;
      // this.utilityService.hideLoadingModal();
      this.$rootScope.$broadcast('refreshJobWorkflow', null);
      this.alertsAndNotificationsService.showAlertWithCallBack("Candidate scoring in Progress", data.message, "success", () => {
        this.modalService.addModalOpenClassToBodyIfAnyModalIsOpen();
      });
    }, error => {
      this.alertsAndNotificationsService.showBannerMessage(error.message, "danger");
    })
  }

}
