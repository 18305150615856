import { CdkDragDrop,
  CdkDrag,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
  CdkDragHandle
} from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalConfig } from 'src/app/core/models/modalConfig';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { FeedbackAuthoringService } from 'src/app/core/services/feedback-authoring.service';
import { AngularModalService } from 'src/app/core/services/modal.service';
import { UserService } from 'src/app/core/services/user.service';
import { MESSAGECONSTANTS } from 'src/app/core/utils/constants';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-add-edit-feedback-forms',
  templateUrl: './add-edit-feedback-forms.component.html',
  styleUrls: ['./add-edit-feedback-forms.component.css']
})
export class AddEditFeedbackFormsComponent implements OnInit {

  @ViewChild('feedbackForm', null) feedbackFormRef: NgForm;
  @Input() feedbackType;
  MESSAGECONSTANTS:any = MESSAGECONSTANTS;
  feedbackFormViewActionsModal: BsModalRef;
  
  company: any;
  clientsOrBUsList: any = [];
  selectedClientOrBu: any;
  activeClientOrBu: any = "";
  questionList: any = [];
  savedQuestionList: any = [];
  userRole: any;
  user: any;
  sliderQuestionsList: any = [];

  loadingClientsFlag: boolean = false;
  loadingQuestionsFlag: boolean = false;
  is4dot5Flag: boolean = false;
  questionsRearrangedFlag: boolean = false;
  isCompanyFlag: boolean = true;
  editModeFlag: boolean = false;
  addingQuestionFlag: boolean = false;
  savingFlag: boolean = false;
  hasAccessToCompanySettings: boolean = false;
  isClientChosen: boolean = false;
  nonEditable: boolean = false;
  isStaffingCompany: boolean = false;
  isEmpty: boolean = false;

  sliderOptions: Options = {
    floor:0,
    ceil: 10,
    step:1,
    showSelectionBar: true,
    hideLimitLabels: true,
    hidePointerLabels: true,
  };

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private userService: UserService,
    private alertsAndNotificationsService: AlertAndNotificationsService,
    private feedbackAuthoringService: FeedbackAuthoringService,
    private angularModalService: AngularModalService,
    private bsModalService: BsModalService
  ) { }

  ngOnInit() {
    this.userRole = this.authService.getUserDetails().roleScreenRef.role.name;

    this.company = this.authService.getUserDetails().company;

    this.isStaffingCompany = this.companyService.isStaffingCompany();
    this.user = this.authService.getUserDetails();

    if (this.company.companyName == '4dot5') {
      this.is4dot5Flag = true;
      this.getCompanyClientQuestions(this.company.companyId, this.feedbackType);
    } else {
      this.loadingClientsFlag = true;
      this._getClientsOrBus();
      if (this._isUserNonAdmin()) {
        this._getUserAccessDetails(() => {
          if (this.hasAccessToCompanySettings) {
            this.getCompanyClientQuestions(this.company.companyId, this.feedbackType);
          } else {
            this.isCompanyFlag = false;
            this.companyClientTypeChanged();
          }
        });
      } else {
        this.getCompanyClientQuestions(this.company.companyId, this.feedbackType);
        this.hasAccessToCompanySettings = true;
      }
    }
  }

  _getUserAccessDetails(callback) {
    this.userService.getLoggedInUserDetails((data) => {
      if (!data.isBUsOrClientsAvailable) {
        this.hasAccessToCompanySettings = true;
        if (callback) {
          callback();
        }
      }
      if (!this.hasAccessToCompanySettings) {
        this._isUserEntityScopeAll(() => {
          if (callback) {
            callback();
          }
        });
      }

    }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _getClientsOrBus() {
    this.userService.getUsersOnlyClientsOrBus(this.company.companyId, (data) => {
      this.clientsOrBUsList = data;
      this.loadingClientsFlag = false;
    }, (error) => {
      this.loadingClientsFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  _isUserNonAdmin() {
    return this.userRole == 'Hiring Manager' || this.userRole == 'CorporateRecruitingManager' || this.userRole == 'StaffingRecruitingManager'
  }

  _isUserEntityScopeAll(callback) {
    this.hasAccessToCompanySettings = true;
    if (callback) {
      callback();
    }
  }

  companyClientTypeChanged() {
    var isCompanyTypeChange = true;
    this.checkForUnsavedData(isCompanyTypeChange);
  }

  _switchClientType() {
    this.addingQuestionFlag = false;
    this.questionsRearrangedFlag = false;
    if (this.isCompanyFlag) {
      this.getCompanyClientQuestions(this.company.companyId, this.feedbackType);
    } else {
      this.questionList = [];
      this.savedQuestionList = [];
    }
    this.isClientChosen = false;
    this.selectedClientOrBu = {};
  }

  clientOrBuChosen() {
    let isCompanyTypeChange = false;
    this.isClientChosen = true;
    this.checkForUnsavedData(isCompanyTypeChange);
  }

  switchClientOrBu() {
    this.addingQuestionFlag = false;
    this.questionsRearrangedFlag = false;
    if (this.selectedClientOrBu) {
      this.activeClientOrBu = this.selectedClientOrBu;
      this.getCompanyClientQuestions(this.selectedClientOrBu.companyId, this.feedbackType);
    } else {
      this.questionList = [];
      this.savedQuestionList = [];
    }
  }

  getCompanyClientQuestions(companyId, feedbackType) {
    this.loadingQuestionsFlag = true;
    this.questionList = [];
    this.savedQuestionList = [];
    this.feedbackAuthoringService.getFeedbackQuestions(this.company.companyId, companyId, feedbackType, (data) => {
      this.nonEditable = data.nonEditable;
      if (data.questionList != null && data.questionList.length != 0) {
        angular.forEach(data.questionList, (val, key) => {
          let question:any = {};
          question.value = val;
          question.editFlag = false;
          question.editedFlag = false;
          question.deleteFlag = false;
          question.addedFlag = false;
          question.originalValue = val;
          question.oldValue = val;
          this.questionList.push(question);
          this.savedQuestionList.push(question);
        });
      }
      this.loadingQuestionsFlag = false;
    }, (error) => {
      this.loadingQuestionsFlag = false;
      this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
    });
  }

  editQuestion(question) {
    question.oldValue = question.value;
    question.editFlag = true;
  }

  cancelQuestionEdit(question) {
    question.value = question.originalValue;
    question.oldValue = question.originalValue;
    question.editFlag = false;
    question.editedFlag = false;
  }

  saveQuestionEdit(index, question) {
    if (question.value.toString().trim() != '') {
      question.isEmpty = false;
      if (question.value != question.oldValue) {
        if (this._isQuestionDuplicate(index, question)) {
          this.alertsAndNotificationsService.showAlert('Duplicate','Question already exists!','warning');
        } else {
          question.oldValue = question.value;
          question.editFlag = false;
          question.editedFlag = true;
        }
      } else {
        this.alertsAndNotificationsService.showAlert('No Changes', 'No changes were made to the question.','warning');
      }
    } else {
      question.isEmpty = true;
    }
  }

  deleteQuestion(question) {
    question.deleteFlag = true;
  }

  cancelQuestionDelete(question) {
    question.deleteFlag = false;
  }

  addQuestion() {
    let question:any = {};
    question.value = '';
    question.editFlag = false;
    question.editedFlag = false;
    question.deleteFlag = false;
    question.addingFlag = true;
    question.addedFlag = false;
    question.originalValue = '';
    question.oldValue = '';
    this.questionList.push(question);
    this.savedQuestionList.push(question);
    this.addingQuestionFlag = true;
  }

  cancelQuestionAdd(index) {
    this.questionList.splice(index, 1);
    this.addingQuestionFlag = false;
  }

  saveQuestionAdd(index, question) {
    if (question.value.toString().trim() != '') {
      this.isEmpty = false;
      if (this._isQuestionDuplicate(index, question)) {
        this.alertsAndNotificationsService.showAlert('Duplicate','Question already exists!','warning');
      } else {
        question.originalValue = question.value;
        question.oldValue = question.value;
        this.addingQuestionFlag = false;
        question.addingFlag = false;
        question.addedFlag = true;
      }
    } else {
      question.isEmpty = true;
    }
  }

  _isQuestionDuplicate(index, question) {
    let isDuplicate = false;
    angular.forEach(this.questionList, (val, key) => {
      if (index != key) {
        if (val.value.toString().trim() == question.value.toString().trim()) {
          isDuplicate = true;
        }
      }
    });
    return isDuplicate;
  }

  showPreview(template: TemplateRef<any>) {
    this.sliderQuestionsList = _.cloneDeep(this.questionList);
    this.sliderQuestionsList.forEach(question => {
      question.rating = 0;
    })
    const config = new ModalConfig();
    const modalData: any = {};
    config.class = `custom-modal-xl custom-ngx-feedback-form-modal`;
    config.initialState = modalData;
    config.ignoreBackdropClick = true;
    this.feedbackFormViewActionsModal = this.bsModalService.show(template, config);
  }

  closeFeedbackFormActionModal() {
    if(this.feedbackFormViewActionsModal) {
      this.feedbackFormViewActionsModal.hide();
    }
  }

  saveQuestions() {
    var saveNeeded = false;
    var questionsToSaveArray = [];
    angular.forEach(this.questionList, (question, key) => {
      if (question.editedFlag || question.addedFlag || question.deleteFlag) {
        saveNeeded = true;
      }
      if (!question.deleteFlag && question.value.trim().length > 0) {
        questionsToSaveArray.push(question.value);
      }
    });
    if (saveNeeded || this.questionsRearrangedFlag) {
      var entityId = '';
      if (this.isCompanyFlag) {
        entityId = this.company.companyId;
      } else {
        entityId = this.selectedClientOrBu.companyId;
      }
      this.feedbackAuthoringService.saveQuestions(entityId, this.feedbackType, questionsToSaveArray, this.company.companyId, (data) => {
        if (this.feedbackType == 'R') {
          this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.FEEDBACK.RECRUITERQUESTIONS, 'success');
        } else if (this.feedbackType == 'P') {
          this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.FEEDBACK.PHONEQUESTIONS, 'success');
        } else {
          this.alertsAndNotificationsService.showBannerMessage(this.MESSAGECONSTANTS.NOTIFICATIONS.FEEDBACK.INTERVIEWQUESTIONS, 'success');
        }
        this.getCompanyClientQuestions(entityId, this.feedbackType);
      }, (error) => {
        this.alertsAndNotificationsService.showBannerMessage(error.message, 'danger');
      });
    } else {
      this.alertsAndNotificationsService.showAlert('No Changes','Nothing to save!','warning');
    }
  }

  questionMoved($index) {
    this.questionList.splice($index, 1);
    this.questionsRearrangedFlag = true;
  }

  checkForUnsavedData(isCompanyTypeChange) {
    var saveNeeded = false;

    angular.forEach(this.questionList, (question, key) => {
      if (question.editedFlag || question.addedFlag || question.deleteFlag || question.editFlag) {
        saveNeeded = true;
      }
    });
    if (saveNeeded || this.questionsRearrangedFlag || this.addingQuestionFlag) {
      this.getUserConfirmation(isCompanyTypeChange);
    } else {
      if (isCompanyTypeChange) {
        this._switchClientType();
      } else {
        this.switchClientOrBu();
      }
    }
  }

  getUserConfirmation(isCompanyTypeChange) {
    this.alertsAndNotificationsService.showConfirm('>Warning!','Unsaved questions will be lost. Do you still want to continue?', 'warning' , false, (result) => {
      if (result) {
        if (isCompanyTypeChange) 
          this._switchClientType();
         else {
          this.switchClientOrBu();
        }
      } else {
        if (isCompanyTypeChange) {
          this.isCompanyFlag = !this.isCompanyFlag;
        } else {
          this.selectedClientOrBu = this.activeClientOrBu;
        }
      }
    }, () => {

    });

  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  } else {
      transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
      );
  }

  }

}
