import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, EMPTY, Subject } from 'rxjs';
import { TagInputComponent } from 'ngx-chips';
import { NewDegree } from 'src/app/core/models/newDegree';
import { NewFieldOfStudy } from 'src/app/core/models/newFieldOfStudy';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { NewRole } from 'src/app/core/models/newRole';
import { NewCertificate } from 'src/app/core/models/newCertificate';
import { NewJDAction } from 'src/app/core/models/newJdAction';

@Component({
  selector: 'app-add-edit-entities',
  templateUrl: './add-edit-entities.component.html',
  styleUrls: ['./add-edit-entities.component.css']
})
export class AddEditEntitiesComponent implements OnInit {

  @ViewChild('addEntityForm', { static: true }) addEntityFormRef: NgForm;
  @Input() action;
  @Input() entityType;
  @Input() entityData;
  @Input() allRequisitionEntities;
  @Output() saveAddEntityCallback = new EventEmitter<any>();
  @Output() saveEditEntityCallback = new EventEmitter<any>();
  @Output() cancelCallback = new EventEmitter<any>();

  addEditEntity: any;
  jDActionMinExperienceError: boolean = false;
  constructor(
    private alertsAndNotificationsService: AlertAndNotificationsService
  ) { }

  ngOnInit() {
    if (this.action === 'add') {
      if (this.entityType === 'degrees') {
        this.addEditEntity = new NewDegree();
      } else if (this.entityType === 'fieldOfStudy') {
        this.addEditEntity = new NewFieldOfStudy();
      } else if (this.entityType === 'roles') {
        this.addEditEntity = new NewRole();
      } else if (this.entityType === 'certificates') {
        this.addEditEntity = new NewCertificate();
      } else if (this.entityType === 'jdActions') {
        this.addEditEntity = new NewJDAction();
      }
    } else {
      this.addEditEntity = JSON.parse(JSON.stringify(this.entityData));
      if (this.entityType === 'degrees' && this.addEditEntity.alternateName && this.addEditEntity.alternateName.length > 0) {
        let alternateList = [];
        this.addEditEntity.alternateName.map(alternateName => {
          let alternate = { display: alternateName, id: alternateName, value: alternateName };
          alternateList.push(alternate);
        });
        this.addEditEntity.alternateName = [...alternateList];
      } else if (this.entityType === 'jdActions') {
        if (this.addEditEntity.jobDescriptionLanguageDropdown && this.addEditEntity.jobDescriptionLanguageDropdown.length > 0) {
          let jobDescriptionLanguageDropdownList = [];
          this.addEditEntity.jobDescriptionLanguageDropdown.map(jobDescriptionLanguageDropdownName => {
            let jobDescriptionLanguageDropdown = { display: jobDescriptionLanguageDropdownName, id: jobDescriptionLanguageDropdownName, value: jobDescriptionLanguageDropdownName };
            jobDescriptionLanguageDropdownList.push(jobDescriptionLanguageDropdown);
          });
          this.addEditEntity.jobDescriptionLanguageDropdown = [...jobDescriptionLanguageDropdownList];
        }
        if (this.addEditEntity.alternates && this.addEditEntity.alternates.length > 0) {
          let alternateList = [];
          this.addEditEntity.alternates.map(alternateName => {
            let alternate = { display: alternateName, id: alternateName, value: alternateName };
            alternateList.push(alternate);
          });
          this.addEditEntity.alternates = [...alternateList];
        }
        if(!this.addEditEntity.minExperienceInMonths) {
          this.addEditEntity.minExperienceInMonths = {value:0, unit: 'months', sign:""}
        }
        if(!this.addEditEntity.maxExperienceInMonths) {
          this.addEditEntity.maxExperienceInMonths = {value:0, unit: 'months', sign:""}
        }
        this.setMinYearsIfExperienceSign();
      }
    }
  }

  addAlternateNameOnEnter(event: any, type, input: TagInputComponent): void {

  }

  setDuplicateEntity(duplicateEntity, entity, type) {
    duplicateEntity.isEntityDuplicate = true;
    duplicateEntity.entity = entity;
    duplicateEntity.duplicateEntityMessage = this.setDuplicateEntityMessage(type);
  }

  setDuplicateEntityMessage(type) {
    const messages = {
      'field-of-study-name': "<b>Field of Study</b> with this name already exists.",
      'degree-name': "<b>Degree</b> with this name already exists.",
      'role-name': "<b>Role</b> with this name already exists.",
      'certificate-name': "<b>Certificate</b> with this name already exists.",
      'jd-action-name': "<b>JD Action</b> with this name already exists."
    };

    return messages[type] || "";
  }

  checkIfEntityNameDuplicate(entity, addEditEntityName) {
    return (entity !== '' && entity.toLowerCase()) === (addEditEntityName !== '' && addEditEntityName.toLowerCase());
  }

  checkFieldOfStudyDuplicate(state, duplicateEntity) {
    if (this.allRequisitionEntities && this.allRequisitionEntities.length > 0) {
      this.allRequisitionEntities.forEach(entity => {
        if (state == 'add' && this.checkIfEntityNameDuplicate(entity.fieldOfStudy, this.addEditEntity.fieldOfStudy)) {
          this.setDuplicateEntity(duplicateEntity, entity, 'field-of-study-name');
          return false;
        } else {
          if (state == 'edit' && (this.entityData.fieldOfStudy.toLowerCase() != this.addEditEntity.fieldOfStudy.toLowerCase())) {
            if (this.checkIfEntityNameDuplicate(entity.fieldOfStudy, this.addEditEntity.fieldOfStudy)) {
              this.setDuplicateEntity(duplicateEntity, entity, 'field-of-study-name');
              return false;
            }
          }
        }
      });
    }
    return duplicateEntity;
  }

  checkRolesDuplicate(state, duplicateEntity) {
    if (this.allRequisitionEntities && this.allRequisitionEntities.length > 0) {
      this.allRequisitionEntities.forEach(entity => {
        if (state == 'add' && this.checkIfEntityNameDuplicate(entity.role, this.addEditEntity.role)) {
          this.setDuplicateEntity(duplicateEntity, entity, 'role-name');
          return false;
        } else {
          if (state == 'edit' && (this.entityData.role.toLowerCase() != this.addEditEntity.role.toLowerCase())) {
            if (this.checkIfEntityNameDuplicate(entity.role, this.addEditEntity.role)) {
              this.setDuplicateEntity(duplicateEntity, entity, 'role-name');
              return false;
            }
          }
        }
      });
    }
    return duplicateEntity;
  }

  checkCertificatesDuplicate(state, duplicateEntity) {
    if (this.allRequisitionEntities && this.allRequisitionEntities.length > 0) {
      this.allRequisitionEntities.forEach(entity => {
        if (state == 'add' && this.checkIfEntityNameDuplicate(entity.name, this.addEditEntity.name)) {
          this.setDuplicateEntity(duplicateEntity, entity, 'certificate-name');
          return false;
        } else {
          if (state == 'edit' && (this.entityData.name.toLowerCase() != this.addEditEntity.name.toLowerCase())) {
            if (this.checkIfEntityNameDuplicate(entity.name, this.addEditEntity.name)) {
              this.setDuplicateEntity(duplicateEntity, entity, 'certificate-name');
              return false;
            }
          }
        }
      });
    }
    return duplicateEntity;
  }

  checkDegreeDuplicate(state, duplicateEntity) {
    duplicateEntity = this.checkAllDegreeDuplicate(state, duplicateEntity);
    if (!duplicateEntity.isEntityDuplicate) {

    }

    return duplicateEntity;
  }

  checkAllDegreeDuplicate(state, duplicateEntity) {
    if (this.allRequisitionEntities && this.allRequisitionEntities.length > 0) {
      this.allRequisitionEntities.forEach(entity => {
        if (state == 'add' && this.checkIfEntityNameDuplicate(entity.degreeName, this.addEditEntity.degreeName)) {
          this.setDuplicateEntity(duplicateEntity, entity, 'degree-name');
          return false;
        } else {
          if (state == 'edit' && (this.entityData.degreeName.toLowerCase() != this.addEditEntity.degreeName.toLowerCase())) {
            if (this.checkIfEntityNameDuplicate(entity.degreeName, this.addEditEntity.degreeName)) {
              this.setDuplicateEntity(duplicateEntity, entity, 'degree-name');
              return false;
            }
          }
        }
      });
    }
    return duplicateEntity;
  }

  checkJdActionsDuplicate(state, duplicateEntity) {
    if (this.allRequisitionEntities && this.allRequisitionEntities.length > 0) {
      this.allRequisitionEntities.forEach(entity => {
        if (state == 'add' && this.checkIfEntityNameDuplicate(entity.actionword, this.addEditEntity.actionword)) {
          this.setDuplicateEntity(duplicateEntity, entity, 'jd-action-name');
          return false;
        } else {
          if (state == 'edit' && (this.entityData.actionword.toLowerCase() != this.addEditEntity.actionword.toLowerCase())) {
            if (this.checkIfEntityNameDuplicate(entity.actionword, this.addEditEntity.actionword)) {
              this.setDuplicateEntity(duplicateEntity, entity, 'jd-action-name');
              return false;
            }
          }
        }
      });
    }
    return duplicateEntity;
  }

  checkIfEntityDuplicate(state) {
    let duplicateEntity = { entity: {}, isEntityDuplicate: false, duplicateEntityMessage: '' };
    if (this.entityType === 'degrees') {
      duplicateEntity = this.checkDegreeDuplicate(state, duplicateEntity);
    } else if (this.entityType === 'fieldOfStudy') {
      duplicateEntity = this.checkFieldOfStudyDuplicate(state, duplicateEntity);
    } else if (this.entityType === 'roles') {
      duplicateEntity = this.checkRolesDuplicate(state, duplicateEntity);
    } else if (this.entityType === 'certificates') {
      duplicateEntity = this.checkCertificatesDuplicate(state, duplicateEntity);
    } else if (this.entityType === 'jdActions') {
      duplicateEntity = this.checkJdActionsDuplicate(state, duplicateEntity);
    }
    return duplicateEntity;
  }

  checkJdActionMinAndMaxValidation() {
    if (this.entityType === 'jdActions') {
        const minExperience = this.addEditEntity.minExperienceInMonths.value;
        const maxExperience = this.addEditEntity.maxExperienceInMonths.value;

        if (this.addEditEntity.minExperienceInMonths.sign == "" && (minExperience >= maxExperience)) {
          this.jDActionMinExperienceError = true;
          return false;
        } else {
          this.jDActionMinExperienceError = false;
        }
    }
    return true;
}

  addEntityDetails() {
    if(this.checkJdActionMinAndMaxValidation()) {
      if (this.addEntityFormRef.valid) {
        let duplicateEntity = this.checkIfEntityDuplicate('add');
        if (!duplicateEntity.isEntityDuplicate) {
          if (this.saveAddEntityCallback) {
            this.saveAddEntityCallback.emit(this.addEditEntity);
            this.cancel();
          }
        } else {
          this.alertsAndNotificationsService.showAlert("Duplicate", duplicateEntity.duplicateEntityMessage, "warning");
        }
      }
    }
  }

  editEntityDetails() {
    if(this.checkJdActionMinAndMaxValidation()) {
      if (this.addEntityFormRef.valid) {
        let duplicateEntity = this.checkIfEntityDuplicate('edit');
        if (!duplicateEntity.isEntityDuplicate) {
          if (this.saveEditEntityCallback) {
            this.saveEditEntityCallback.emit(this.addEditEntity);
            this.cancel();
          }
        } else {
          this.alertsAndNotificationsService.showAlert("Duplicate", duplicateEntity.duplicateEntityMessage, "warning");
        }
      }
    }
  }

  cancel() {
    this.cancelCallback.emit();
  }

  setMinYearsIfExperienceSign() {
    if(this.addEditEntity.minExperienceInMonths.sign == '+') {
      this.addEditEntity.maxExperienceInMonths.value = 0;
      this.addEditEntity.maxExperienceInMonths.sign = "";
      this.jDActionMinExperienceError = false;
    }
  }

}
