import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-profile-page',
  templateUrl: './job-profile-page.component.html',
  styleUrls: ['./job-profile-page.component.css']
})
export class JobProfilePageComponent implements OnInit {

  @Input() $transition$:any;

  stateParams:any;
  breadCrumbLabel: any;
  breadCrumbStateAddress: any;
  jobId: any;

  constructor(
    @Inject("$state") public $state: any,
    @Inject("$rootScope") public $rootScope: any,
  ) { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    //variables
    this.breadCrumbLabel = 'Requisitions';
    this.breadCrumbStateAddress = 'missioncontrol.requisitions';
    this.jobId = "5d627b8d80ee226eb8c0762e";

    if(!_.isNull(this.$rootScope.fromState) && this.$rootScope.fromState.name == 'missioncontrol.candidates') {
        this.breadCrumbLabel = 'Candidates';
        this.breadCrumbStateAddress = 'missioncontrol.candidates';
    }
  }


  redirectToBreadCrumbStateUrl() {
    this.redirectToUrl(this.breadCrumbStateAddress, true);
  }

  redirectToUrl(url, isReloadRequired) {
    this.$state.go(url, null, { reload: isReloadRequired });
  }

}
