import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-forms-page',
  templateUrl: './feedback-forms-page.component.html',
  styleUrls: ['./feedback-forms-page.component.css']
})
export class FeedbackFormsPageComponent implements OnInit {

  @Input() $transition$: any;

  stateParams: any;
  feedbackType: String = 'R';

  constructor() { }

  ngOnInit() {
    this.stateParams = this.$transition$.params();
    this.feedbackType = this.stateParams.feedbackType;
  }

}
