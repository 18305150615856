<div *ngIf="uploadType.type === 'uploadSkills'">
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">Excel file format</h4>
            <table class="table table-bordered table-responsive file-format-content">
                <tbody>
                <tr>
                    <th>skill<span class="text-danger">*</span></th>
                    <th>category<span class="text-danger">*</span></th>
                    <th>alternate</th>
                    <th>parent</th>
                    <th>operation</th>
                </tr>
                <tr>
                    <td>HTML</td>
                    <td>TECHNICAL</td>
                    <td>Hyper Text Markup Language</td>
                    <td>frontend</td>
                    <td></td>
                </tr>
                <tr>
                    <td>CSS</td>
                    <td>TECHNICAL</td>
                    <td></td>
                    <td>frontend</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Aws</td>
                    <td>TECHNICAL</td>
                    <td>azure|gcp</td>
                    <td>cloud</td>
                    <td>delete</td>
                </tr>
                <tr>
                    <td>Java</td>
                    <td>OPERATIONAL</td>
                    <td>spring|hibernate</td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">CSV file format</h4>
            <div class="border p-4 file-format-content">
                <p>
                    skill,category,alternate,parent,operation
                </p>
                <p>
                    HTML,TECHNICAL,Hyper Text Markup Language,frontend
                </p>
                <p>
                    CSS,TECHNICAL,,frontend
                </p>
                <p>
                    Aws,TECHNICAL,azure|gcp,cloud,delete
                </p>
                <p>
                    Java,OPERATIONAL,spring|hibernate,backend
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
            <a class="btn btn-primary mt-3" href="assets/app-content/sampleFiles/sample-skills.csv" target="_blank">
                <span class="pr-2"><i class="fas fa-download"></i></span>
                <span>Download sample file</span>
            </a>
        </div>
    </div>
</div>

<div *ngIf="uploadType.action === 'degrees'">
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">Excel file format</h4>
            <table class="table table-bordered table-responsive file-format-content">
                <tbody>
                    <tr>
                        <th>Order<span class="text-danger">*</span></th>
                        <th>Degree Name<span class="text-danger">*</span></th>
                        <th>Alternate Name</th>
                        <th>Full Name</th>
                        <th>Enable Field of Study</th>
                        <th>Show in paranthesis</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>GED</td>
                        <td></td>
                        <td>GED</td>
                        <td>FALSE</td>
                        <td>FALSE</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Diploma</td>
                        <td></td>
                        <td>Diploma</td>
                        <td>TRUE</td>
                        <td>FALSE</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Advanced Diploma</td>
                        <td>Associate</td>
                        <td>Associate</td>
                        <td>TRUE</td>
                        <td>FALSE</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Bachelors</td>
                        <td>BS,B.S.,Bachelor's</td>
                        <td>Bachelors</td>
                        <td>TRUE</td>
                        <td>FALSE</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">CSV file format</h4>
            <div class="border p-4 file-format-content">
                <p>
                    Order,Degree Name,Alternate Name,Full Name,Enable Field of Study,Show in paranthesis
                </p>
                <p>
                    1,GED,,GED,FALSE,FALSE
                </p>
                <p>
                    2,Diploma,,Diploma,TRUE,FALSE
                </p>
                <p>
                    3,Advanced Diploma,Associate,Associate,TRUE,FALSE
                </p>
                <p>
                    4,Bachelors,BS|B.S.|Bachelor's,Bachelors,TRUE,FALSE
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
            <a class="btn btn-primary mt-3" href="assets/app-content/sampleFiles/sample-degrees-list.csv"
                target="_blank">
                <span class="pr-2"><i class="fas fa-download"></i></span>
                <span>Download sample file</span>
            </a>
        </div>
    </div>
</div>
<div *ngIf="uploadType.action === 'fieldOfStudy'">
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">Excel file format</h4>
            <table class="table table-bordered table-responsive file-format-content">
                <tbody>
                    <tr>
                        <th>Field of Study<span class="text-danger">*</span></th>
                    </tr>
                    <tr>
                        <td>Aerodynamics</td>
                    </tr>
                    <tr>
                        <td>Cybersecurity Engineering</td>
                    </tr>
                    <tr>
                        <td>Cybersecurity Policy</td>
                    </tr>
                    <tr>
                        <td>Dairy Engineering</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">CSV file format</h4>
            <div class="border p-4 file-format-content">
                <p>
                    Field of Study
                </p>
                <p>
                    Aerodynamics
                </p>
                <p>
                    Cybersecurity Engineering
                </p>
                <p>
                    Cybersecurity Policy
                </p>
                <p>
                    Dairy Engineering
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
            <a class="btn btn-primary mt-3" href="assets/app-content/sampleFiles/sample-field-of-study-list.csv"
                target="_blank">
                <span class="pr-2"><i class="fas fa-download"></i></span>
                <span>Download sample file</span>
            </a>
        </div>
    </div>
</div>

<div *ngIf="uploadType.action === 'roles'">
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">Excel file format</h4>
            <table class="table table-bordered table-responsive file-format-content">
                <tbody>
                    <tr>
                        <th>Role<span class="text-danger">*</span></th>
                    </tr>
                    <tr>
                        <td>Actuarial IT Analyst</td>
                    </tr>
                    <tr>
                        <td>Aerospace IT Specialist</td>
                    </tr>
                    <tr>
                        <td>Agricultural IT Specialist</td>
                    </tr>
                    <tr>
                        <td>AI Research Scientist</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">CSV file format</h4>
            <div class="border p-4 file-format-content">
                <p>
                    Role
                </p>
                <p>
                    Actuarial IT Analyst
                </p>
                <p>
                    Aerospace IT Specialist
                </p>
                <p>
                    Agricultural IT Specialist
                </p>
                <p>
                    AI Research Scientist
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
            <a class="btn btn-primary mt-3" href="assets/app-content/sampleFiles/sample-candidate-role.csv"
                target="_blank">
                <span class="pr-2"><i class="fas fa-download"></i></span>
                <span>Download sample file</span>
            </a>
        </div>
    </div>
</div>

<div *ngIf="uploadType.action === 'certificates'">
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">Excel file format</h4>
            <table class="table table-bordered table-responsive file-format-content">
                <tbody>
                    <tr>
                        <th>Name<span class="text-danger">*</span></th>
                        <th>Group<span class="text-danger">*</span></th>
                    </tr>
                    <tr>
                        <td>CompTIA A+</td>
                        <td>General IT Certification</td>
                    </tr>
                    <tr>
                        <td>CompTIA Network+</td>
                        <td>General IT Certification</td>
                    </tr>
                    <tr>
                        <td>Certified Ethical Hacker (CEH)</td>
                        <td>Cybersecurity Certification</td>
                    </tr>
                    <tr>
                        <td>GIAC Security Essentials (GSEC)</td>
                        <td>Cybersecurity Certification</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">CSV file format</h4>
            <div class="border p-4 file-format-content">
                <p>
                    Name,Group
                </p>
                <p>
                    CompTIA A+,General IT Certification
                </p>
                <p>
                    CompTIA Network++,General IT Certification
                </p>
                <p>
                    Certified Ethical Hacker (CEH),Cybersecurity Certification
                </p>
                <p>
                    GIAC Security Essentials (GSEC),Cybersecurity Certification
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
            <a class="btn btn-primary mt-3" href="assets/app-content/sampleFiles/sample-certifications.csv"
                target="_blank">
                <span class="pr-2"><i class="fas fa-download"></i></span>
                <span>Download sample file</span>
            </a>
        </div>
    </div>
</div>

<div *ngIf="uploadType.action === 'jdActions'">
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">Excel file format</h4>
            <table class="table table-bordered table-responsive file-format-content">
                <tbody>
                    <tr>
                        <th>Order<span class="text-danger">*</span></th>
                        <th>Skills and Proficiency<span class="text-danger">*</span></th>
                        <th>Description<span class="text-danger">*</span></th>
                        <th>Minimum Experience In Months<span class="text-danger">*</span></th>
                        <th>Maximum Experience In Months<span class="text-danger">*</span></th>
                        <th>Job Description Language dropdown<span class="text-danger">*</span></th>
                        <th>Alternates</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Guru</td>
                        <td>An acknowledged leader and expert in a field, often with the ability to teach or mentor others.</td>
                        <td>180+</td>
                        <td>0</td>
                        <td>Wizard at</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Master</td>
                        <td>Someone with comprehensive knowledge and skill in a particular area.</td>
                        <td>120</td>
                        <td>180</td>
                        <td>Mastery of</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Expert</td>
                        <td>A person with a high level of skill or knowledge in a specific area.</td>
                        <td>84</td>
                        <td>120</td>
                        <td>Expert in, Highly skilled in</td>
                        <td>Expertise in, Specialized in (7+)</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Advanced</td>
                        <td>Possessing more than the basic or average skills in a particular area.</td>
                        <td>60</td>
                        <td>84</td>
                        <td>Advanced skills in, Advanced experience with</td>
                        <td>Strong experience with,Advanced knowledge of, Advanced proficiency in, Extensive Experience with</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <h4 class="border-bottom-0 mt-0 text-center">CSV file format</h4>
            <div class="border p-4 file-format-content">
                <p>
                    Order,Skills and Proficiency,Description,Minimum Experience In Months,Maximum Experience In Months,Job Description Language dropdown,Alternates
                </p>
                <p>
                    1,Guru,An acknowledged leader and expert in a field often with the ability to teach or mentor others.,180+,0,Wizard at,,
                </p>
                <p>
                    2,Master,Someone with comprehensive knowledge and skill in a particular area.,120,180,Mastery of,,
                </p>
                <p>
                    3,Expert,A person with a high level of skill or knowledge in a specific area.,84,120,Expert in|Highly skilled in,Expertise in|Specialized in (7+)
                </p>
                <p>
                    4,Advanced,Possessing more than the basic or average skills in a particular area.,60,84,Advanced skills in|Advanced experience with,Strong experience with|Advanced knowledge of|Advanced proficiency in|Extensive Experience with
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
            <a class="btn btn-primary mt-3" href="assets/app-content/sampleFiles/sample-jd-actions.csv"
                target="_blank">
                <span class="pr-2"><i class="fas fa-download"></i></span>
                <span>Download sample file</span>
            </a>
        </div>
    </div>
</div>