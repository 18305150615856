<div class="row">
    <div class="col-xs-12">
        <div class="main-box">
            <div class="main-box-body" style="padding-top: 20px;">
                <form #feedbackForm="ngForm" name="feedbackForm" novalidate>
                    <div class="row">
                        <div class="col-xs-12 col-sm-8 col-sm-offset-1">
                            <div class="row" *ngIf="is4dot5Flag">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <label style="padding-right: 10px; font-size: 1.2em;">
                                            Feedback Questions for : <strong>4dot5</strong>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!is4dot5Flag">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <label style="padding-right: 10px; font-size: 1.2em;">
                                            Feedback Questions for :
                                        </label>
                                        <div class="radio" style="display: inline;" [ngClass]="{'feedback-form-authoring-question-disabled': editModeFlag || !hasAccessToCompanySettings}">
                                            <input type="radio" name="companyType" id="feedbackQuestionsTypeCompany"
                                                   [value]="true" [(ngModel)]="isCompanyFlag"
                                                   [disabled]="editModeFlag || !hasAccessToCompanySettings" (change)="companyClientTypeChanged()">
                                            <label for="feedbackQuestionsTypeCompany">
                                                <strong>{{ company.companyName }}</strong>
                                            </label>
                                        </div>
                                        <div class="radio" style="display: inline;">
                                            <input #companyType="ngModel" type="radio" name="companyType" id="feedbackQuestionsTypeClient"
                                                   [value]="false" [(ngModel)]="isCompanyFlag"
                                                   [disabled]="editModeFlag" (change)="companyClientTypeChanged()">
                                            <label for="feedbackQuestionsTypeClient">
                                                <strong>{{ company.companyType == 'StaffingCompany' ? 'Client' : 'BU'}}</strong>
                                            </label>
                                        </div>
                                        <div *ngIf="!is4dot5Flag && !isCompanyFlag" style="display: inline;">
                                            <label>
                                                <span *ngIf="company.companyType == 'StaffingCompany'">Select : </span>
                                                <span *ngIf="company.companyType == 'Corporation'">BU : </span>
                                            </label>
                                            <select #clientOrBu="ngModel" *ngIf="!loadingClientsFlag" class="form-control access" style="display: inline; margin-top: -5px; width: 200px" name="clientOrBu"
                                                    id="clientOrBu" [(ngModel)]="selectedClientOrBu" (change)="clientOrBuChosen()" required>
                                                    <option [ngValue]="option" *ngFor="let option of clientsOrBUsList; let i = index">
                                                        {{option.companyName}}
                                                    </option>
                                            </select>
                                            <span *ngIf="loadingClientsFlag">
                                                Loading <i class="fa fa-spin fa-spinner"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 text-right">
                                    <div class="clearfix" style="margin-bottom: 10px;">
                                        <button type="button" class="btn btn-success" (click)="addQuestion()" [disabled]="(!isClientChosen && !isCompanyFlag && isStaffingCompany) || nonEditable">
                                            <span class="fa fa-plus-circle"></span> Add Question
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="main-box">
                                        <div class="main-box-body feedback-form-authoring-question-container" [ngClass]="{'disabledworkflow nopointerevents': nonEditable}">
                                            <div
                                                class="row"
                                                *ngIf="!loadingQuestionsFlag && questionList.length > 0"
                                                style="padding-top: 10px;"
                                                cdkDropList
                                                (cdkDropListDropped)="drop($event)"
                                                [cdkDropListData]="questionList"
                                            >
                                                <div
                                                    class="col-xs-12"
                                                    *ngFor="let question of questionList; let i = index"
                                                    cdkDrag
                                                    cdkDragHandle
                                                    [ngClass]="{
                                                        'selected': selected === question,
                                                        'bg-danger': question.deleteFlag,
                                                        'bg-info': question.addedFlag,
                                                        'bg-warning': question.editedFlag
                                                    }"
                                                    style="padding: 10px; border: 0.5px grey dotted; border-radius: 5px; margin-bottom: 8px;"
                                                >
                                                    <div class="row" style="padding: 3px;">
                                                        <div class="col-xs-10 clearfix">
                                                            <div class="pull-left cursor-pointer" style="padding-right: 10px">
                                                                <i class="fas fa-arrows-alt"></i>
                                                            </div>
                                                            <div class="pull-left" *ngIf="!question.editFlag && !question.addingFlag">
                                                                {{ i + 1 }}. {{ question.value }}
                                                            </div>
                                                            <div class="pull-left" *ngIf="question.editFlag || question.addingFlag" style="width: 90%">
                                                                {{ i + 1 }}.
                                                                <span>
                                                                    <input
                                                                        name="question-{{i + 1}}"
                                                                        type="text"
                                                                        [(ngModel)]="question.value"
                                                                        style="width: 90%"
                                                                        [ngClass]="{'feedback-form-empty-question': question.isEmpty}"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-2">
                                                            <span
                                                                class="fa-stack pull-left emerald cursor-pointer"
                                                                tooltip="Edit Question"
                                                                placement="bottom"
                                                                style="padding-right: 5px;"
                                                                *ngIf="!question.editFlag && !question.editedFlag && !question.deleteFlag && !question.addingFlag && !question.addedFlag"
                                                                (click)="editQuestion(question)"
                                                            >
                                                                <i class="fa fa-square fa-stack-2x"></i>
                                                                <i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i>
                                                            </span>
                                                            <span
                                                                class="fa-stack pull-left emerald cursor-pointer"
                                                                tooltip="Undo Edit Question"
                                                                placement="bottom"
                                                                style="padding-right: 5px;"
                                                                *ngIf="question.editFlag || question.editedFlag"
                                                                (click)="cancelQuestionEdit(question)"
                                                            >
                                                                <i class="fa fa-square fa-stack-2x"></i>
                                                                <i class="fa fa-undo fa-stack-1x fa-inverse"></i>
                                                            </span>
                                                            <span
                                                                class="fa-stack pull-left emerald cursor-pointer"
                                                                tooltip="Update Question"
                                                                placement="bottom"
                                                                style="padding-right: 5px;"
                                                                *ngIf="question.editFlag"
                                                                (click)="saveQuestionEdit(i, question)"
                                                            >
                                                                <i class="fa fa-square fa-stack-2x"></i>
                                                                <i class="fa fa-save fa-stack-1x fa-inverse"></i>
                                                            </span>
                                                            <span
                                                                class="fa-stack pull-left emerald cursor-pointer"
                                                                tooltip="Undo Add Question"
                                                                placement="bottom"
                                                                style="padding-right: 5px;"
                                                                *ngIf="question.addingFlag || question.addedFlag"
                                                                (click)="cancelQuestionAdd(i, question)"
                                                            >
                                                                <i class="fa fa-square fa-stack-2x"></i>
                                                                <i class="fa fa-undo fa-stack-1x fa-inverse"></i>
                                                            </span>
                                                            <span
                                                                class="fa-stack pull-left emerald cursor-pointer"
                                                                tooltip="Save Question"
                                                                placement="bottom"
                                                                style="padding-right: 5px;"
                                                                *ngIf="question.addingFlag"
                                                                (click)="saveQuestionAdd(i, question)"
                                                            >
                                                                <i class="fa fa-square fa-stack-2x"></i>
                                                                <i class="fa fa-save fa-stack-1x fa-inverse"></i>
                                                            </span>
                                                            <span
                                                                class="fa-stack pull-left emerald cursor-pointer"
                                                                tooltip="Undo Delete Question"
                                                                placement="bottom"
                                                                style="padding-right: 5px;"
                                                                *ngIf="question.deleteFlag"
                                                                (click)="cancelQuestionDelete(question)"
                                                            >
                                                                <i class="fa fa-square fa-stack-2x"></i>
                                                                <i class="fa fa-undo fa-stack-1x fa-inverse"></i>
                                                            </span>
                                                            <span
                                                                class="fa-stack pull-left red cursor-pointer"
                                                                tooltip="Delete Question"
                                                                placement="bottom"
                                                                *ngIf="!question.editFlag && !question.deleteFlag && !question.addingFlag && !question.addedFlag"
                                                                (click)="deleteQuestion(question)"
                                                            >
                                                                <i class="fa fa-square fa-stack-2x"></i>
                                                                <i class="far fa-trash-alt fa-stack-1x fa-inverse"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="!loadingQuestionsFlag && questionList.length == 0">
                                                <div class="col-xs-12 text-center">
                                                    <div style="margin-top: 30px;">
                                                        No questions available.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xs-12 loading-spinner text-center" *ngIf="loadingQuestionsFlag">
                                                <span>Loading Questions</span>
                                                <span class="fa fa-spinner fa-spin"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="row">
                                <div class="col-xs-6 pull-left">
                                    <div class="clearfix">
                                        <button type="button" class="btn btn-success" (click)="showPreview(feedbackFormActionsFormat)"
                                                [disabled]="savingFlag || questionList.length == 0">
                                            Show Preview
                                        </button>
                                    </div>
                                </div>
                                <div class="col-xs-6 pull-right text-right">
                                    <div class="clearfix">
                                        <button type="submit" class="btn btn-success" (click)="saveQuestions()" [disabled]="savingFlag || nonEditable">
                                            <span *ngIf="!savingFlag">Save</span>
                                            <span *ngIf="savingFlag">Saving..</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #feedbackFormActionsFormat>
    <div class="modal-header" style="padding: 15px !important; border-bottom: 1px solid #e5e5e5;">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
            (click)="closeFeedbackFormActionModal()">×</button>
        <h4 class="modal-title wrap-text-in">Feedback Form Preview</h4>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12">
                <div class="main-box">
                    <div class="main-box-body">
                        <table width="95%" style="margin-top: 10px;" *ngFor="let question of sliderQuestionsList; let i = index" class="feedback-question-table">
                            <tr>
                                <td colspan="2" style="padding-bottom: 25px">
                                    <span class="preview-question-{{i}}">{{i + 1}}. {{question.value}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td width="50px">Rating :</td>
                                <td style="width: 200px;">
                                    <!-- <div class="preview-question-slider" id="preview-question-slider-{{i}}"></div> -->
                                    <div class="d-flex align-items-center">
                                        <ngx-slider [(value)]="question.rating" [options]="sliderOptions" class="m-0"></ngx-slider>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="text-align: center">
                                    <span id="preview-question-rating-{{i}}">{{question.rating}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="height: 10px"></td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <textarea style="resize:none;" class="form-control" id="preview-question-comments-{{i}}" rows="2" [(ngModel)]="question.recruiterComment"></textarea>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>