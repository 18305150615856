<div class="row">
    <div class="col-lg-12">
        <div id="content-header" class="clearfix">
            <div class="pull-left">
                <ol class="breadcrumb">
                    <li><a (click)="redirectToUrl('missioncontrol.dashboard', true)">Home</a></li>
                    <li><a (click)="redirectToBreadCrumbStateUrl()"><span>{{breadCrumbLabel}}</span></a></li>
                    <li class="active">Job Profile</li>
                </ol>
                <h1 class="heading-css">Job Profile</h1>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs-12">
        <app-job-profile [jobId]="jobId"></app-job-profile>
    </div>
</div>