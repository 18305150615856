import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackFormsPageComponent } from './feedback-forms-page.component';
import { AddEditFeedbackFormsModule } from './add-edit-feedback-forms/add-edit-feedback-forms.module';

@NgModule({
  declarations: [FeedbackFormsPageComponent],
  imports: [
    CommonModule,
    AddEditFeedbackFormsModule
  ],
  exports: [FeedbackFormsPageComponent],
  entryComponents: [FeedbackFormsPageComponent]
})
export class FeedbackFormsPageModule { }
