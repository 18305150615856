import { downgradeComponent } from "@angular/upgrade/static";
import { FeedbackFormsPageComponent } from './feedback-forms-page.component';

export const FeedbackFormsPageAjsModule = angular
    .module('feedbackFormsPage', [])
    .directive('feedbackFormsPage', downgradeComponent({ component: FeedbackFormsPageComponent }))
    .config(
        ['$stateProvider', ($stateProvider) => {
            $stateProvider.state('missioncontrol.feedbackforms', {
                url: '/feedbackforms',
                component: FeedbackFormsPageComponent,
                data: {
                    requireLogin: true
                },
                params: {
                    feedbackType: null
                }
            });
        }
        ])
    .name;
