<div class="approw">
    <div class="row">
        <div class="col-lg-12">
            <div id="content-header" class="clearfix">
                <div class="pull-left">
                    <h1 class="heading-css" *ngIf="feedbackType == 'R'">Recruiter Feedback</h1>
                    <h1 class="heading-css" *ngIf="feedbackType == 'P'">Phone Screen Feedback</h1>
                    <h1 class="heading-css" *ngIf="feedbackType == 'I'">Interview Feedback</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <app-add-edit-feedback-forms [feedbackType]="feedbackType"></app-add-edit-feedback-forms>
        </div>
    </div>
</div>



