export class JobProfile {
    id: any;
    title: any;
    minYears: any;
    maxYears: any;
    companyName: any;
    educations: any = [];
    experiences: any = [];
    certifications: any = [];
    compensations: any = [];
    technicalSkills: any = [];
    softSkills: any = [];
    extraSkills: any = [];
    others: any = [];
}