import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobProfileComponent } from './job-profile.component';
import { TabsModule, TooltipModule } from 'ngx-bootstrap';
import { InfoIconModule } from 'src/app/shared/info-icon/info-icon.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [JobProfileComponent],
  imports: [
    CommonModule,
    TabsModule.forRoot(),
    TooltipModule,
    InfoIconModule,
    PipesModule
  ],
  exports: [JobProfileComponent],
  entryComponents: [JobProfileComponent]
})
export class JobProfileModule { }
