import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobProfilePageComponent } from './job-profile-page.component';
import { JobProfileModule } from './job-profile/job-profile.module';

@NgModule({
  declarations: [JobProfilePageComponent],
  imports: [
    CommonModule,
    JobProfileModule
  ],
  exports: [JobProfilePageComponent],
  entryComponents: [JobProfilePageComponent]
})
export class JobProfilePageModule { }
